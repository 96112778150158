import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import { useGridStyles } from './styled/StudiesViewMakeStyles';
import { InnerSpanHTML } from '../Common/InnerHTML';
import { GetFormat } from '../../utils/functions';
import Loading from '../Common/Loading';
import { webViewerEndpoints } from '../../../config';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

export const StudiesGridTable = ({ attributes, items, loading, selected, handleSelected, SelectedIcon, dark }) => {
  const { t } = useTranslation();
  const [sentinel, setSentinel] = useState();
  const styles = { offsetY: sentinel && sentinel.getBoundingClientRect().y, dark };
  const classes = useGridStyles(styles);
  const columns = attributes.filter((attribute) => !attribute.hidden);

  const isBrowser = () => typeof window !== 'undefined';

  const rows = items && items.map((item) => {
    const row = item.node;
    row.modality = row && row.modalities && Array.isArray(row.modalities) ? row.modalities.join(', ') : row.modalities;
    return row;
  });

  const handleGoto = (index) => {
    if (!isBrowser) return;

    const dicomStudy = items && items[index] && items[index].node;
    const url = `${webViewerEndpoints.getStudy}${dicomStudy.studyInstanceUid}`;
    window.open(url, '_blank');
  };

  const handleClick = (event, index, row) => {
    event.stopPropagation();

    if (row === 0) {
      handleSelected(index);
      return;
    }
    handleGoto(index);
  };

  const CellContent = ({ column, row, itemSelected }) => {
    const content = GetFormat(row[column.key], column.format);

    switch (column.key) {
      case 'select':
        return <SelectedIcon selected={itemSelected} />;
      case 'open':
        return <OpenInNew />;
      default:
        return <InnerSpanHTML content={content} />;
    }
  };

  const tableCellClassname = (row) => {
    switch (row) {
      case 0:
        return 'actions';
      default:
        return 'cursor';
    }
  };

  if (items && !items.length) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="info" title={t('dicom.studies')}>
          {t('no.studies.found')}
        </AlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <Box component={Paper} className={classes.tableContainer}>
      {loading && (
        <Box className="loading">
          <Loading />
        </Box>
      )}
      <TableContainer className={classes.tableWrapper}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns && columns.map((column, index) => (
                <TableCell key={index.toString()} style={{ width: column.width }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody ref={(i) => setSentinel(i)} className={classes.body}>
            {rows && rows.map((row, index) => {
              const itemSelected = !!selected.length && selected.includes(index);
              return (
                <TableRow
                  role="checkbox"
                  aria-checked={itemSelected}
                  key={index.toString()}
                  className={classes.rows}
                  tabIndex={-1}
                >
                  {columns && columns.map((column, index2) => (
                    <TableCell
                      key={index2.toString()}
                      className={tableCellClassname(index2)}
                      onClick={(event) => handleClick(event, index, index2)}
                    >
                      <CellContent column={column} row={row} itemSelected={itemSelected} />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            <TableRow className={classes.last}>
              {columns && columns.map((column, index2) => (
                <TableCell key={index2.toString()}>&zwnj;</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
