import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import moment from 'moment-timezone';

import { Menu, MenuItem } from '@material-ui/core';
import {
  ArrowDropDown,
  ArrowDropUp,
  CreateTwoTone,
  CloudDownloadTwoTone,
  CloudUpload,
  DeleteTwoTone,
  InfoOutlined,
  InvertColors,
  InvertColorsTwoTone,
  Sort,
  ToggleOn,
  ToggleOff,
  ViewComfy,
  ViewList,
  Web,
} from '@material-ui/icons';
import { useKeycloak } from 'react-keycloak';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { GET_HOSPITAL_STUDIES_TO_LIST } from '../../graphql/queries';
import { SectionBar } from '../../componentsUI/SectionBar';
import { DeleteStudyDialog } from '../Study/modal/DeleteStudyDialog';
import { DOWNLOAD_DICOM_STUDY } from '../../graphql/mutations';
import { DownloadDicomFile } from '../../utils/DicomFileUtils';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI, ResponsiveListWrapperUI } from '../Common/styled/ContainerUI';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';
import { webViewerEndpoints } from '../../../config';
import { EditStudyDialog } from '../Study/modal/EditStudyDialog';
import { StudiesListView } from './StudiesListView';
import { StudiesGridView } from './StudiesGridView';
import { DATEFORMAT } from '../../utils/functions';
import { StudiesFilterView } from './StudiesFilterView';

const sortData = {
  default: { field: 'CREATED_AT', direction: 'DESC' },
};

export const StudiesView = ({ type = 'grid' }) => {
  const { t } = useTranslation();
  const listGrid = type === 'grid';
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const hospital = useSelector((state) => state.hospital);
  const [keycloak] = useKeycloak();
  const [darkStyles, setDarkStyles] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const filterElementRef = useRef();
  const [cursor, setCursor] = useState(null);
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);
  const [viewFilters, setViewFilters] = useState(listGrid);
  const [viewAsGrid, setViewAsGrid] = useState(listGrid);
  const [studiesPerPage, setStudiesPerPage] = useState(25);
  const [downloadDicomStudy, { loading: requesting }] = useMutation(DOWNLOAD_DICOM_STUDY);

  const toggleEditModal = () => setShowEditModal(!showEditModal);
  const toggleColorStyles = () => setDarkStyles(!darkStyles);

  const isBrowser = () => typeof window !== 'undefined';

  const orderByFields = [
    { label: 'patient.name', id: 'node.patientName', field: listGrid && 'PATIENT_NAME', direction: 'ASC', width: 370 },
    { label: 'study.title', id: 'node.title', field: 'TITLE', direction: 'ASC', width: 370 },
    { label: 'created.at', id: 'node.createdAt', field: 'CREATED_AT', direction: 'DESC', width: 120, format: 'DATEFORMAT' },
  ];

  const attributes = [
    { key: 'select', width: 10, type: 'icon' },
    { key: 'patientId', label: t('patient.id'), width: 100, filter: true },
    { key: 'patientName', label: t('patient.name'), width: 125, filter: true },
    { key: 'title', label: t('study.title'), width: 175, filter: true },
    { key: 'createdAt', label: t('date'), width: 75, format: DATEFORMAT },
    { key: 'modality', label: t('modality'), width: 50, filter: true },
    { key: 'numberOfSeries', label: t('series'), width: 50, type: 'number', sortable: false },
    { key: 'numberOfInstances', label: t('instances'), width: 50, type: 'number', sortable: false },
    { key: 'studyInstanceUid', label: 'ID', width: 150, hidden: true },
    { key: 'description', label: t('description'), width: 300 },
  ];

  const queryFilters = {};
  attributes
    .filter((field) => field.filter)
    .forEach((filter) => { queryFilters[filter.key] = filters[filter.key]; });

  const queryFirst = !listGrid ? 50 : studiesPerPage;

  const { loading, data, error, refetch, fetchMore } = useQuery(
    GET_HOSPITAL_STUDIES_TO_LIST,
    {
      variables: {
        hospitalUuid: hospital.uuid,
        first: queryFirst,
        cursor,
        createdAtAfter: moment(filters.fromDate).startOf('day ').format('YYYY-MM-DD HH:mm:ss'),
        createdAtBefore: moment(filters.untilDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        ...(Object.keys(queryFilters).length && { filters: queryFilters }),
        orderBy: {
          field: orderByField,
          direction: orderByDirection,
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const study = (data && data.dicomStudies && selected.length > 0)
    && data.dicomStudies.edges[selected[0]] && data.dicomStudies.edges[selected[0]].node;

  const fetchMoreStudies = (fetchMoreCb) => {
    const { endCursor } = data.dicomStudies.pageInfo;

    fetchMore({
      query: GET_HOSPITAL_STUDIES_TO_LIST,
      variables: {
        hospitalUuid: hospital.uuid,
        first: 25,
        cursor: endCursor,
        orderBy: { field: orderByField, direction: orderByDirection },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.dicomStudies.edges;

        return newEdges.length ? {
          dicomStudies: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.dicomStudies.__typename,
            totalCount: previousResult.dicomStudies.totalCount,
            pageInfo: fetchMoreResult.dicomStudies.pageInfo,
            edges: [...previousResult.dicomStudies.edges, ...newEdges],
          },
        } : previousResult;
      },
    })
      .then(({ data: fetchMoreData }) => (
        fetchMoreData.dicomStudies.pageInfo.hasNextPage && fetchMoreCb()
      ));
  };

  const reverseDirection = () => (
    orderByDirection === 'ASC' ? 'DESC' : 'ASC'
  );

  const multipleSelect = false;
  const handleSelected = (index) => {
    if (typeof index === 'undefined') {
      setSelected([]);
      return;
    }
    setSelected((selected.length && selected.includes(index))
      ? selected.filter((option) => option !== index)
      : [...multipleSelect ? selected : [], index]);
  };

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch().then(() => {
        setAnchorEl();
        setSelected([]);
      });
    }
  };

  const handleOpen = (evt) => {
    evt.stopPropagation();
    navigate(`/study/${study.uuid}`);
  };

  const openInWebViewer = () => {
    const url = `${webViewerEndpoints.getStudy}${study.studyInstanceUid}`;
    if (isBrowser) window.open(url, '_blank');
  };

  const handleUpload = () => {
    navigate(`/study/upload/${hospital.uuid}?uth=1`);
  };

  const handleDownload = async () => {
    try {
      toast(t('requesting.study.download'), { className: 'toast-info' });
      await downloadDicomStudy({ variables: { uuid: study.uuid } });
      DownloadDicomFile(study, keycloak);
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) toast(errors.graphQLErrors[0].message, { className: 'toast-error' });
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteModal(false);
    setSelected([]);
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilters = (selection) => {
    setCursor(null);
    setSelected([]);
    setFilters(selection);
  };

  const toggleListView = () => {
    if (!viewAsGrid) {
      setCursor(null);
      setViewAsGrid(true);
      setViewFilters(true);
      return;
    }
    setViewFilters(false);
    setTimeout(() => setViewAsGrid(false), 500);
  };

  const toggleFilterView = () => {
    setViewFilters(!viewFilters);
  };

  const FilterIcon = viewFilters ? ToggleOn : ToggleOff;
  const ListIcon = viewAsGrid ? ViewList : ViewComfy;
  const ColorsIcon = darkStyles ? InvertColorsTwoTone : InvertColors;
  const filterLabel = viewFilters ? 'hide.filters' : 'view.filters';
  const listLabel = viewAsGrid ? 'view.as.list' : 'view.grid';
  const styleLabel = darkStyles ? 'theme.view' : 'dark.view';

  const buttons = [
    { name: 'upload.study', icon: CloudUpload, handleClick: handleUpload, disabled: false },
    { name: 'divider1', type: 'divider' },
    { name: 'view.study.info', icon: InfoOutlined, handleClick: handleOpen, disabled: selected.length !== 1 },
    { name: 'open.in.viewer', icon: Web, handleClick: openInWebViewer, disabled: selected.length !== 1 },
    { name: 'download', icon: CloudDownloadTwoTone, handleClick: handleDownload, disabled: selected.length !== 1 || requesting, hidden: !viewAsGrid },
    { name: 'edit.study.info', icon: CreateTwoTone, handleClick: toggleEditModal, disabled: selected.length !== 1 || requesting, hidden: viewAsGrid },
    { name: 'delete', icon: DeleteTwoTone, handleClick: () => setShowDeleteModal(true), disabled: selected.length !== 1, hidden: viewAsGrid },
    { name: 'divider2', type: 'divider' },
    { name: filterLabel, icon: FilterIcon, handleClick: toggleFilterView, disabled: false },
    { name: listLabel, icon: ListIcon, handleClick: toggleListView },
    { name: styleLabel, icon: ColorsIcon, handleClick: toggleColorStyles, disabled: listGrid, hidden: true },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC')
      ? <ArrowDropDown className={className} />
      : <ArrowDropUp className={className} />
  );

  const errorMessage = networkErrorParse(error);

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <ContainerUI>
      <Navbar>
        {!!study && (
          <>
            <DeleteStudyDialog open={showDeleteModal} onClose={handleCloseDelete} study={study} refetch={refetch} />
            <EditStudyDialog open={showEditModal} onClose={toggleEditModal} study={study} />
          </>
        )}
        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {orderByFields.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>
        <SectionBar title="studies" items={buttons} />
      </Navbar>
      <ResponsiveListWrapperUI>
        {filters && (
          <StudiesFilterView
            attributes={attributes}
            filters={filters}
            viewFilters={viewFilters}
            queryFilters={queryFilters}
            handleFilters={handleFilters}
            reference={filterElementRef}
            dark={darkStyles}
          />
        )}
        {viewAsGrid ? (
          <StudiesGridView
            attributes={attributes}
            data={data}
            loading={loading}
            selected={selected}
            handleSelected={handleSelected}
            filterElement={filterElementRef && filterElementRef.current}
            rowsPerPage={studiesPerPage}
            setRowsPerPage={setStudiesPerPage}
            setCursor={setCursor}
            dark={darkStyles}
          />
        ) : (
          <StudiesListView
            loading={loading}
            data={data}
            fetchMoreStudies={fetchMoreStudies}
            order={{ field: orderByField, direction: orderByDirection }}
            handleOrderBy={handleOrderBy}
            selected={selected}
            handleSelected={handleSelected}
            filterElement={filterElementRef && filterElementRef.current}
          />
        )}
      </ResponsiveListWrapperUI>
    </ContainerUI>
  );
};
