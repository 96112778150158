import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Container, Paper, TablePagination } from '@material-ui/core';
import { HighlightOff, RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons';

import { useGridStyles } from './styled/StudiesViewMakeStyles';
import { StudiesGridTable } from './StudiesGridTable';
import { GetFormat, DATEFORMAT } from '../../utils/functions';
import { Strong } from '../Common/styled/Text';
import { IconTooltipUI } from '../../componentsUI/IconTooltip';

const SelectedIcon = ({ selected }) => (selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />);
const getElementHeight = (element) => element && element.getBoundingClientRect().height;

export const StudiesGridView = ({
  attributes,
  data,
  loading,
  dark,
  selected,
  handleSelected,
  filterElement,
  rowsPerPage,
  setRowsPerPage,
  setCursor,
}) => {
  const { t } = useTranslation();
  const { fixSidebar } = useSelector((state) => state && state.userInterface);
  const [page, setPage] = useState(0);
  const [totalStudies, setTotalStudies] = useState(0);
  const [pageReference, setPageReference] = useState({ 0: null });
  const [sentinel, setSentinel] = useState();
  const [filterHeight, setFilterHeight] = useState(0);
  const studies = data && data.dicomStudies;
  const styles = {
    offsetX: fixSidebar ? 285 : 0,
    offsetY: sentinel && sentinel.getBoundingClientRect().y,
    dark,
  };
  const classes = useGridStyles(styles);
  const updateFilterHeight = () => {
    const height = getElementHeight(filterElement);
    if (height !== filterHeight) setFilterHeight(height);
  };
  setTimeout(updateFilterHeight, 750);

  if (studies && (studies.totalCount !== totalStudies)) {
    setTotalStudies(studies.totalCount);
    setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    if (loading) return;
    if (!studies) return;

    if (newPage > page) {
      pageReference[newPage] = studies.pageInfo.endCursor;
      setPageReference(pageReference);
    }
    setPage(newPage);
    setCursor(pageReference[newPage]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageReference({ 0: null });
    setCursor(0);
  };

  const handleSelectedItem = (sel) => {
    setFilterHeight(filterHeight - 1);
    handleSelected(sel);
  };

  const labelDisplayedRows = ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`;

  const selectedItem = selected && selected.length === 1 && studies && studies.edges[selected[0]].node;
  const SelectedItemComponent = () => (
    <Box className={classes.selected}>
      <Box>
        <Strong>{`1 ${t('selected')}`}</Strong>
        :
      </Box>
      <Box>{selectedItem.title}</Box>
      <Box>{selectedItem.modality}</Box>
      <Box className="patient">{selectedItem.patientName}</Box>
      <Box>{GetFormat(selectedItem.createdAt, DATEFORMAT)}</Box>
      <Box>
        <IconTooltipUI
          Icon={HighlightOff}
          title={t('unselect')}
          handleClick={() => handleSelectedItem(selected[0])}
          size="2rem"
        />
      </Box>
    </Box>
  );

  const SelectedItems = () => (
    <Paper elevation={1}>
      <Box>
        <Strong>{`${selected.length} ${t('selected')}`}</Strong>
      </Box>
      <Box>
        <HighlightOff onClick={() => handleSelectedItem()} />
      </Box>
    </Paper>
  );

  return (
    <Container maxWidth="lg" ref={(i) => setSentinel(i)} className={classes.section}>
      <Container className={classes.selection}>
        {selected && selected.length === 1 && <SelectedItemComponent />}
        {selected && selected.length > 1 && <SelectedItems />}
      </Container>
      <Container className={classes.main}>
        <Box className={classes.wrapper}>
          <Paper elevation={2} className={classes.box}>
            <StudiesGridTable
              attributes={attributes}
              items={studies && studies.edges}
              loading={loading}
              selected={selected}
              handleSelected={handleSelectedItem}
              SelectedIcon={SelectedIcon}
              dark={dark}
            />
          </Paper>
        </Box>
      </Container>
      <Container maxWidth="lg" className={classes.pagination}>
        <Box>
          {studies && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={studies.totalCount}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={t('rows.per.page')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={labelDisplayedRows}
            />
          )}
        </Box>
      </Container>
    </Container>
  );
};
